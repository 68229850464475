<template>
  <layout-default-dynamic>
    <page-content :id="id" />
  </layout-default-dynamic>
</template>

<script>
import LayoutDefaultDynamic from '../layouts/LayoutDefaultDynamic'
import PageContent from '../components/PageContent'

export default {
  components: {
    LayoutDefaultDynamic,
    PageContent,
  },
  name: 'page',
  props: {
    id: {
      type: [Number, String],
    },
  },
  metaInfo() {
    return {
      title: `zijschittert`,
      meta: [
        {
          name: 'description',
          content:
            'Coaching voor vrouwen vanuit bijbels perspectief. - Bible-based coaching for women.',
        },
      ],
    }
  },
}
</script>
