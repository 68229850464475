<template>
  <b-container>
    <section v-if="errored">
      <p>Sorry er is iets mis gegaan met het laden van deze pagina...</p>
    </section>
    <section v-else>
      <div v-if="loading" class="d-flex flex-column">
        <skeleton-box height="380px" width="100%" class="mt-1" />
        <skeleton-box height="38px" width="30%" class="mt-3" />
        <div v-for="index in 3" :key="index" class="mb-3">
          <skeleton-box
            v-for="index in 5"
            :key="index"
            height="20px"
            class="mt-2"
          />
        </div>
      </div>
      <div v-else>
        <b-row>
          <b-col class="px-sm-0">
            <article class="mb-3 wp-content">
              <img
                v-if="page.afbeelding"
                :src="page.afbeelding"
                alt="Image"
                class="card-img-top mb-3"
              />
              <h1 class="" v-html="page.title.rendered"></h1>
              <component :is="dynamicComponent(page.content.rendered)" />
            </article>
          </b-col>
          <b-col v-if="page.sidebar_kop" md="4" class="pr-sm-0">
            <section class="mb-3 border-0">
              <h2
                class="text-center typ-context-title-2"
                v-html="page.sidebar_kop"
              ></h2>
              <img
                v-if="page.sidebar_afbeelding"
                :src="page.sidebar_afbeelding.url"
                :alt="page.sidebar_afbeelding.alt"
                class="sidebar-img img-fluid rounded-circle mx-auto d-block"
              />
              <p
                v-if="page.sidebar_tekst"
                class="mt-2 text-center"
                v-html="page.sidebar_tekst"
              ></p>
            </section>
          </b-col>
        </b-row>
      </div>
    </section>
  </b-container>
</template>

<script>
import SkeletonBox from './SkeletonBox'
import ZsWhatsapp from './Dynamic/ZsWhatsapp'
import { WpService } from '../services/wp.service'
import { mapState } from 'vuex'
import { BRow, BCol, BContainer } from 'bootstrap-vue'

export default {
  name: 'PageContent',
  components: {
    SkeletonBox,
    'b-container': BContainer,
    'b-row': BRow,
    'b-col': BCol,
  },
  props: {
    id: {
      type: [Number, String],
    },
  },
  data() {
    return {
      page: null,
      loading: true,
      errored: false,
    }
  },
  computed: {
    ...mapState(['lang']),
  },
  watch: {
    lang(newValue) {
      this.getContent(newValue, this.id)
    },
    id(newValue) {
      this.getContent(this.lang, newValue)
    },
  },
  methods: {
    getContent(lang, id) {
      WpService.page(id, lang)
        .then((response) => (this.page = response))
        .catch(() => {
          this.errored = true
        })
        .finally(() => (this.loading = false))
    },
    dynamicComponent(template) {
      return {
        template: `<div>${template}</div>`,
        components: {
          ZsWhatsapp,
        },
      }
    },
  },
  mounted() {
    this.getContent(this.lang, this.id)
  },
}
</script>

<style scoped lang="scss">
.sidebar-img {
  max-width: 200px;
}
</style>
