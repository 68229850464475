<template>
  <whatsapp-link variant="button" buttonVariant="" buttonSize="" block />
</template>

<script>
import WhatsappLink from '../WhatsappLink'

export default {
  name: 'ZsWhatsapp',
  components: {
    WhatsappLink,
  },
}
</script>
