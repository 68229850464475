import { ApiService, ApiError } from './api.service'

//import { TokenService } from './storage.service'

const WpService = {
  pages: async function () {
    const resource = '/wordpress/wp-json/wp/v2/pages'
    try {
      const response = await ApiService.get(resource)
      return response.data
    } catch (error) {
      throw new ApiError(error.response.status, error.response.data.detail)
    }
  },

  page: async function (id, lang) {
    const resource = '/wordpress/wp-json/wp/v2/pages/' + id
    try {
      const response = await ApiService.get(resource, {
        params: {
          context: 'view',
          //lang: "en"
          lang: lang,
        },
      })
      return response.data
    } catch (error) {
      throw new ApiError(error.response.status, error.response.data.detail)
    }
  },

  posts: async function (lang, categories) {
    const resource = '/wordpress/wp-json/wp/v2/posts'
    try {
      const response = await ApiService.get(resource, {
        params: {
          context: 'view',
          lang: lang,
          categories: categories || '',
        },
      })
      return response.data
    } catch (error) {
      throw new ApiError(error.response.status, error.response.data.detail)
    }
  },

  postBySlug: async function (lang, slug) {
    const resource = '/wordpress/wp-json/wp/v2/posts/'
    try {
      const response = await ApiService.get(resource, {
        params: {
          context: 'view',
          lang: lang,
          slug: slug,
        },
      })
      if (!response.data || !response.data[0])
        throw new ApiError('Post not Found', 'Post not Found')
      return response.data[0]
    } catch (error) {
      throw new ApiError(error.response.status, error.response.data.detail)
    }
  },

  categories: async function (lang) {
    const resource = '/wordpress/wp-json/wp/v2/categories'
    try {
      const response = await ApiService.get(resource, {
        params: {
          context: 'view',
          //lang: "en"
          lang: lang,
          exclude: '1,7',
          order: 'desc',
        },
      })
      return response.data
    } catch (error) {
      throw new ApiError(error.response.status, error.response.data.detail)
    }
  },
  postComment: async function (form, post) {
    const resource = '/wordpress/wp-json/wp/v2/comments'
    try {
      const response = await ApiService.post(
        resource,
        {
          author_name: form.name,
          author_email: form.email,
          content: form.text,
          post: post,
        },
        {
          // context: "view",
          //lang: "en"
          // lang: lang
          //crossdomain: true
        }
      )
      return response.data
    } catch (error) {
      throw new ApiError(error.response.status, error.response.data.detail)
    }
  },

  comments: async function (lang, postId) {
    const resource = '/wordpress/wp-json/wp/v2/comments'
    try {
      const response = await ApiService.get(resource, {
        params: {
          context: 'view',
          lang: lang,
          post: postId,
        },
      })
      if (response.data.status == 409) {
        throw new ApiError(response.data.status, response.code)
      }
      return response.data
    } catch (error) {
      throw new ApiError(error.response.status, error.response.data.detail)
    }
  },

  postLike: async function (postId) {
    const resource = '/wordpress/wp-json/wp/v2/likes/' + postId + '/like'
    try {
      const response = await ApiService.post(
        resource,
        {
          // author_name: form.name,
          // author_email: form.email,
          // content: form.text,
          // post: post
        },
        {
          // context: "view",
          //lang: "en"
          // lang: lang
          //crossdomain: true
        }
      )
      return response.data
    } catch (error) {
      throw new ApiError(error.response.status, error.response.data.detail)
    }
  },

  //   /**
  //    * Login the user and store the access token to TokenService.
  //    *
  //    * @returns access_token
  //    * @throws AuthenticationError
  //    **/
  //   login: async function(email, password) {
  //     const requestData = {
  //       method: "post",
  //       url: "/o/token/",
  //       data: {
  //         grant_type: "password",
  //         username: email,
  //         password: password
  //       },
  //       auth: {
  //         username: process.env.VUE_APP_CLIENT_ID,
  //         password: process.env.VUE_APP_CLIENT_SECRET
  //       }
  //     };

  //     try {
  //       const response = await ApiService.customRequest(requestData);

  //       TokenService.saveToken(response.data.access_token);
  //       TokenService.saveRefreshToken(response.data.refresh_token);
  //       ApiService.setHeader();

  //       // NOTE: We haven't covered this yet in our ApiService
  //       //       but don't worry about this just yet - I'll come back to it later
  //       ApiService.mount401Interceptor();

  //       return response.data.access_token;
  //     } catch (error) {
  //       throw new AuthenticationError(
  //         error.response.status,
  //         error.response.data.detail
  //       );
  //     }
  //   },

  //   /**
  //    * Refresh the access token.
  //    **/
  //   refreshToken: async function() {
  //     const refreshToken = TokenService.getRefreshToken();

  //     const requestData = {
  //       method: "post",
  //       url: "/o/token/",
  //       data: {
  //         grant_type: "refresh_token",
  //         refresh_token: refreshToken
  //       },
  //       auth: {
  //         username: process.env.VUE_APP_CLIENT_ID,
  //         password: process.env.VUE_APP_CLIENT_SECRET
  //       }
  //     };

  //     try {
  //       const response = await ApiService.customRequest(requestData);

  //       TokenService.saveToken(response.data.access_token);
  //       TokenService.saveRefreshToken(response.data.refresh_token);
  //       // Update the header in ApiService
  //       ApiService.setHeader();

  //       return response.data.access_token;
  //     } catch (error) {
  //       throw new AuthenticationError(
  //         error.response.status,
  //         error.response.data.detail
  //       );
  //     }
  //   },

  //   /**
  //    * Logout the current user by removing the token from storage.
  //    *
  //    * Will also remove `Authorization Bearer <token>` header from future requests.
  //    **/
  //   logout() {
  //     // Remove the token and remove Authorization header from Api Service as well
  //     TokenService.removeToken();
  //     TokenService.removeRefreshToken();
  //     ApiService.removeHeader();

  //     // NOTE: Again, we'll cover the 401 Interceptor a bit later.
  //     ApiService.unmount401Interceptor();
  //   }
}

export default WpService

export { WpService, ApiError }
